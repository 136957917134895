import React from "react";
import './Contato.scss';

const Contato = () => {
    return (
        <section className="caixa-contato">
            <main className="container px-4 py-5" id="featured-3">
                <div id="Contato" className="form-wrapper-contato">
                    <div className="alinhamento-contato">
                        <div className="info-text-contato">
                            <div className="alinhamento-text-contato">
                                <p className="txt-form-contato">
                                    mande uma mensagem
                                    pra gente!
                                </p>
                                <h2 className="titulo-principal-contato">Formulário de Contato</h2>
                                <h4 className="sub-titulo-contato">
                                    Use para nos enviar
                                    uma pergunta ou
                                    só para dar um oi! :)
                                </h4>
                                <div className="alinhamento-link-contato">
                                    <p className="txt-link-contato">Endereço: 
                                        <a className="info-link-contato" href="https://goo.gl/maps/zPesJqNwB8q8pXiZ8" target="_blank" rel="noreferrer" alt="Digital !D">
                                            Clique Aqui
                                        </a>
                                    .</p>
                                    <p className="txt-link-contato">E-mail: 
                                        <a className="info-link-contato" href="mailto:mkt@digitalid.com.br" alt="Digital !D">
                                            Clique Aqui
                                        </a>
                                    .</p>
                                    <p className="txt-link-contato">Nosso Site: 
                                        <a className="info-link-contato" href="https://digitalid.com.br/" target="_blank" rel="noreferrer" alt="Digital !D">
                                            Clique Aqui
                                        </a>
                                    .</p>
                                </div>
                            </div>
                        </div>

                        <div className="formulario">
                            <div className="alinhamento-form-contato">
                                <form method="POST" action="https://formsubmit.co/mkt@digitalid.com.br">
                                    <input type="text" name="Nome" className="inputs" placeholder="Nome" required/>
                                    <input type="email" name="Email" className="inputs" placeholder="Email" required/>
                                    <textarea type="text" name="Mensagem" className="msg-form" placeholder="Mensagem" style={{ resize:"none"}} maxLength="80" required/>
                                    <input type="hidden" name="_captcha" value="false"/>
                                    <input type="hidden" name="_next" value="https://digitalid.com.br/"></input>
                                    <button className="link-btn-contato" type="submit">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}

export default Contato;