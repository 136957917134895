import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import './Time.scss';

const Time = () => {
    return (
        <section className="caixa-time">
            <div className='bg-time'>
                <div className="texto_mobile_wrapper">
                    <h2>Nosso Time</h2>
                    <p>
                        Contamos com uma equipe de <strong>especialistas</strong> em 
                        diversas áreas de expertise, para <strong>melhorar ainda mais
                        seu negocio</strong>.
                    </p>
                </div>
                <div id="Time" className="conjunto-container-time">
                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/moni.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Monica Muchak</h2>
                            <p className='texto-time'>Diretora de Arte</p>
                        </div>
                    </div>

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/vane.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Vanessa Marochi</h2>
                            <p className='texto-time'>Designer</p>
                        </div>
                    </div>

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/susan.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Susan Machado</h2>
                            <p className='texto-time'>Designer</p>
                        </div>
                    </div>
                </div>

                <div className="conjunto-container-time">

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/amanda.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Amanda Nishimura</h2>
                            <p className='texto-time'>Analista</p>
                        </div>
                    </div>

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/mario.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Mario Boss</h2>
                            <p className='texto-time'>Gestor</p>
                        </div>
                    </div>

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/Yasmin.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Yasmin Oliveira</h2>
                            <p className='texto-time'>Administrativo</p>
                        </div>
                    </div>
                </div>

                <div className="conjunto-container-time espacamento-time">

                    <div className="item-time">
                        <div className="img-wrapper-time">
                            <StaticImage src="../../images/gustavo.png" alt="Digital !D" placeholder="blurred"/>
                        </div>
                        <div className="text-wrapper-time">
                            <h2 className='titulo-time'>Gustavo Moreira</h2>
                            <p className='texto-time'>Desenvolvedor</p>
                        </div>
                    </div>
                </div>
            </div>        
        </section>
    )
}

export default Time;