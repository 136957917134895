import * as React from "react";

import Header from '../Components/header/Header';
import Principal from "../Components/Main/MainPrincipal";
import Boxing from "../Components/Boxing/box_azul";
import Service from "../Components/service/Service";
import Portifolio from "../Components/portfolio/Portfolio";
import Sobre from "../Components/sobre/Sobre";
import Time from "../Components/time/Time";
import Localizacao from "../Components/localizacao/localizacao";
import Contato from "../Components/contato/Contato";
import Footer from "../Components/footer/Footer";

// markup
const IndexPage = () => {
  return (
   <>
      <title>Digital !D - Marketing Digital</title>
      <Header/>
      <Principal/>
      <Boxing/>
      <Service/>
      <Portifolio/>
      <Sobre/>
      <Time/>
      <Localizacao/>
      <Contato/>
      <Footer/>

   </>
  )
}

export default IndexPage;
