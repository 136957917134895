import React from "react";

import { MdLocationOn } from 'react-icons/md';
import './localizacao.scss';

const Localizacao = () => {
    return (
        <>
            <div className='caixa-localizacao'>
                <div className="localiza-wrapper-localizacao">
                    <div className="info-localizacao">
                        <div className="textos-localizacao">
                            <div className="icone-wrapper-localizacao">
                            <h1 className='icone-localizacao'><MdLocationOn/></h1>
                            </div>
                            <h5 className='sub-titulo-localizacao'>Digital !D</h5>
                            <h2 className='titulo-localizacao'>Localização</h2>
                            <div className="limitador-localizacao">
                                <p className='texto-localizacao'>
                                    Av. Presidente Affonso Camargo, 2467 -
                                    Jardim Botânico, Curitiba - PR
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="localizacao-map">
                        <div className="posicao-localizacao">
                            <iframe 
                                className="localizacao" title="localização" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.0868654383635!2d-49.238710302613974!3d-25.4399905892707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce50712f1fa2d%3A0x41483e5aa7e0fa9c!2sAv.%20Presidente%20Affonso%20Camargo%2C%202467%20-%20Cristo%20Rei%2C%20Curitiba%20-%20PR%2C%2080050-370!5e1!3m2!1spt-BR!2sbr!4v1648821839698!5m2!1spt-BR!2sbr" 
                                allowFullScreen=""
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Localizacao;