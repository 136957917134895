import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import './Portfolio.scss';

const Portifolio = () => {
    
    return (
        
        <section className="caixa-portfolio">
            <div id="Portfolio" className='caixa-wrapper-portfolio'>
                <div className="container px-4 py-5" id="featured-3">
                    <div className='alinhamento-portfolio'>
                        <div id="Portfolio" className='wrapper-portfolio'>
                            <p className='texto-portfolio' alt="franquia marketing digital">Portfólio</p>
                            <h2 className='titulo-portfolio' alt="trafego pago">Conheça um pouco dos nossos trabalhos</h2>
                            <div className='img-wrapper-portfolio'>
                                <div className='img-portfolio'>
                                    <StaticImage className='port-img' src="../../images/portfolio.png"  alt="Criação de Design e Logotipos" placeholder="blurred"/>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default Portifolio;