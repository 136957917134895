// import logo from './logo.png';
import React from "react";
import logo from '../../images/logo.png';
import { FaWhatsapp } from 'react-icons/fa';
import './Header.scss';

const Header = () => {

  return (
    <>
      <section className='header-mobile'> 
        <nav className="navbar navbar-expand-md navbar-dark fixed-top cor-header">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img className='img-logo-header' src={logo} alt="Digital !D" />
            </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className='menu-wrapper'>
                <ul className="navbar-nav me-auto mb-2 mb-md-0 posi-menu">
                  <li className="nav-item">
                    <a className="nav-link active borda" aria-current="page" href="/">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="#Portfolio">Portfólio</a>
                  </li>
                  <li className="nav-item">
                    <a href="#Sobre" className="nav-link active">Sobre</a>
                  </li>
                  <li className="nav-item">
                    <a href="#Time" className="nav-link active">Time</a>
                  </li>
                  <li className="nav-item">
                    <a href="#Contato" className="nav-link active">Contato</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </section>
       
      <section className='header-desktop'>
        <div className='wrapper-header'>
          <div className='menu-principal-header'>
            <span className='menu-desktop'>
              <a className='borda' href="/">Home</a>
              <a href="#Portfolio">Portfólio</a>
              <a href="#Sobre">Sobre</a>
              <a href="#Time">Time</a>
              <a href="#Contato">Contato</a>
            </span>
          </div>
        </div>
      </section>
        
      <a id='transparencia' href="http://api.whatsapp.com/send?phone=554197218018&text=Olá,+gostaria+de+saber+mais+sobre+os+serviços+de+marketing" target="blank">
        <div id='neon-rotativo'>
          <i title="Fale Conosco" className="link-topo">
            <span>
              <FaWhatsapp/>
            </span>
          </i>      
        </div>
        <p className='info-whats-btn'>
          Fale Conosco agora!
        </p>                        
      </a>
    </>
  )
}

export default Header;