import React from "react";
import './Sobre.scss';

const Sobre = () => {
    return (
        <>
            <section className='caixa-sobre'>
                <div className='img-sobre' id="Sobre">
                    <div className='wrapper-sobre'>                    
                        <h2 className='titulo-wrapper-sobre'>
                            Com <strong>excelência</strong> e <strong>muita criatividade</strong>,
                                iniciamos nossa <strong>Empresa de estratégia digital </strong> 
                                    para suprir a nova demanda de
                                        pessoas insatisfeitas 
                                    com seus resultados
                                e mostrar que <strong>Marketing não é gasto</strong>, e
                            sim <strong>investimento</strong>.
                        </h2> 
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sobre;