import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube, FaLinkedin, FaBehance} from 'react-icons/fa';
import './Fonte.scss';

const Footer = () => {
    return (
        <>
            <section className='caixa-footer'>
                <div className='footer-wrapper-footer'>
                    <div className='copy-footer'>
                        <div className='redes-footer'>
                            <a className='link-footer' href="https://api.whatsapp.com/send?phone=+5541988430759&text=olá+vim+pelo+site+gostaria+de+saber+mais" alt="Digital !D" target="_blank" rel="noreferrer">
                               <FaWhatsapp/>
                            </a>
                            <a className='link-footer' href="https://www.facebook.com/digitalid.mdigital/" alt="Digital !D" target="_blank" rel="noreferrer">
                                <FaFacebook/>
                            </a>
                            <a className='link-footer' href="https://www.instagram.com/sejadigitalid/" alt="Digital !D" target="_blank" rel="noreferrer">
                               <FaInstagram/>
                            </a>
                        </div>
                        <div className='redes2-footer'>
                            <p className='txt-copy-footer'>
                                &copy; 2022 Digital !D - Direitos Reservados.
                            </p>
                        </div>
                        <div className='redes-footer'>
                            <a className='link-footer' href="https://www.linkedin.com/company/digitalidmarketing/" alt="Digital !D" target="_blank" rel="noreferrer">
                                <FaLinkedin/>
                            </a>
                            <a className='link-footer' href="https://www.behance.net/sejadigitalid" alt="Digital !D" target="_blank" rel="noreferrer">
                                <FaBehance/>
                            </a>
                            <a className='link-footer' href="/" alt="Digital !D" target="_blank" rel="noreferrer">
                                <FaYoutube/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;