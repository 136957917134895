import React from 'react';
import './Main.scss';
import { StaticImage } from 'gatsby-plugin-image'

const Principal = () => {
    
    return (
        <>
            <StaticImage className='img-wrapper-main' src="../../images/background.png" alt="Digital ID - Marketing Digital" loading="eager" placeholder='blurred'/>

            <div className='caixa-main'>
                <div className='titulo-wrapper-main'>
                    <h1 className='titulo-main'>Digital !D</h1>
                    <p className='txt-main'>
                        Nós ajudamos você a se comunicar 
                        com uma grande estratégia de marketing.
                    </p> 
                </div>
            </div>

            <div className='bola'></div>
            <div className='bola2'></div>
        </>
    )
}

export default Principal;