import React from "react";
import './Boxing.scss';

const Boxing = () => {
    return ( 
        <section className="caixa-boxing">
            <div className='wrapper-sessao-info-main'>
                <div className='sessao-info-main'>
                    <div className='centralizador'>           
                        <div className='escopo-menor-main'> 
                            <div className='info-wrapper-main'>
                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-agency' alt="agencia de marketing curitiba">Agência</div>
                                        <p className='info-texto-main' alt="marketing digital">Digital !D</p>
                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-email' alt="redes sociais">E-mail</div>
                                        <p className='info-texto-main' alt="anuncio facebook">mtk@digitalid.com.br</p>
                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-whats'>WhatsApp</div>
                                        <p className='info-texto-main' alt="anuncio instagram">(41) 3073-1308</p>
                                    </div>
                                </div>

                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-endereco'>Endereço</div>
                                        <p className='info-endereco-main' alt="agencia de publicidade">
                                            Av. Presidente Affonso Camargo, 2467 - Jardim Botânico, Curitiba - PR
                                        </p>
                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-somos'>O que somos?</div>
                                        <p className='info-texto-main' alt="propaganda">Uma empresa de estratégia digital</p>
                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='titulo-texto-main'><div className='bg-time-box'>Horários</div>
                                        <p className='info-texto-main' alt="midia off">
                                            Seg a Sex 
                                            8h às 17h40 
                                        </p>
                                    </div>
                                </div>
                            </div>     
                        </div>  
                    </div>            
                </div>
            </div>
        </section>
    )
}

export default Boxing;