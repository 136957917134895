import React from "react";
import './Service.scss';

import Icone from  '../../images/icone-1.png';
import Icone2 from '../../images/icone-3.png';
import Icone3 from '../../images/icone-2.png';
import Icone4 from '../../images/icone-4.png';

const Service = () => {
    return (
       
        <section className="caixa-service">
            <div className="wrapper-service">
                <div className='bg-service'>
                    <div className="centralizador-service">
                        <div className="caixa-icones-service">
                            
                            <div className="escopo-icones-service">
                                <div className='centralizador-escopo-icones-service'>
                                    <div className="wrapper-icone-service">
                                        <img className='img-icone-service' src={Icone} alt="digital Id"/>
                                    </div>
                                    <p className="text-dev">01</p>
                                    <h2 className="titulo-icone">Design</h2>
                                    <p className="texto-design">
                                        Contrate nossos designers
                                        para criar layouts incríveis
                                        e experiências visuais únicas e 
                                        autênticas.
                                    </p>
                                </div>
                            </div>

                            <div className="escopo-icones-service">
                                <div className="wrapper-icone-service">
                                    <img className='img-icone-service' src={Icone2} alt="digital Id"/>
                                </div>
                                <p className="text-dev">02</p>
                                <h2 className="titulo-icone">Social Media</h2>
                                <p className="texto-midia">
                                    Atraia o público nas principais
                                    redes sociais e aumente o
                                    fluxo de clientes.
                                </p>
                            </div>

                            <div className="escopo-icones-service">
                                <div className="wrapper-icone-service">
                                    <img className='img-icone-service' src={Icone3} alt="digital Id"/>
                                </div>
                                <p className="text-dev">03</p>
                                <h2 className="titulo-icone">Marketing Digital</h2>
                                <p className="texto-mkt">
                                    Marque a sua presença no mundo 
                                    digital, contrate nossa equipe
                                    de Marketing.  
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="centralizador-service">
                        <div className="desenvolvimento-wrapper">
                            <div className="dev-wrapper">
                                <div className='escopo-dev-service'>
                                    <h1 className="efeito-transparente">Site</h1>                                      
                                    <div className="wrapper-iconedev-service">
                                        <img className='img-icone-service' src={Icone4} alt="digital ID"/>
                                    </div>
                                    <p className="numero-dev">04</p>
                                    <h1 className="titulo-dev">Desenvolvimento WEB</h1>
                                    <p className="texto-dev">
                                        Apareça no mundo digital com o seu próprio site ou landing page.
                                        Está desenvolvendo algo grande? Converse conosco!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service;